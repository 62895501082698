import { Screen1 } from '../components/screens/Screen1';
import { Screen2 } from '../components/screens/Screen2';
import { Screen3 } from '../components/screens/Screen3';

export const screens = [
    {
        id: 0,
        component: Screen1
    },
    {
        id: 1,
        component: Screen2
    },
    {
        id: 2,
        component: Screen3
    },
];